
import React, {  useState } from "react";
import Faq from "react-faq-component";
import "./faq.css";


const data = {
    
    
    rows: [
        {
            title:
                `What are BIM Services?`,
            content: `BIM stands for Building Information Modeling and is a process that starts with the creation of an intelligent 3D model that enables document management, coordination, and simulation throughout the entire lifecycle of a construction project. The services that BIM companies provide are diverse and can include modeling, coordination, fabrication, implementation – amongst others – and their main purpose is to help individuals, businesses and government agencies to plan, design, operate and maintain projects more efficiently.`,
        },
        {
            title: "Why is BIM important?",
            content:
                "As the world population is increasing rapidly, the AEC industry needs to be smart enough to find new, clever and more efficient ways to design and build. This needs to be done not only to keep up with the global demand but to also play an active part in creating spaces that are smarter and more resilient as well. In this scenario, BIM is a key asset: it not only allows design and construction teams to work more efficiently, but it also allows them to capture the data they create during the process to benefit operation and maintenance activities. This is the main reason why BIM mandates are increasing across the world.",
        },
        {
            title: "How we save your time?",
            content: `We at BIMSEAS realize that making drawings and Models can take up a lot of your as well as your team's time. You end up paying more than your budget.
            We are focused on delivering projects that require minimal markups to save your time in marking up. This reduces markup time and minimizes review cycles, saving you time.</br>
            <b>Your Advantage:</b> Maintain your Focus and Gain Competetive advantage.</br>
             
            With less time needed to review and coordinate with us, you can focus on your core competencies to gain competitive advancement.
             
            `,
        },
        {
            title: "How can we complete multiple projects at the same time?",
            content: `We design our processes such that we can deliver complete Projects that require minimal corrections.</br>
            <b>Your Advantage:</b> Get to the next Project Faster. </br>
            Delivering Projects on time can help you move on to the next project faster. In addition, you are able to handle more Projects in the same time.`,
        },
        { 
            title:"How we benefit you with true cost reduction-shorter review cycle?",
            content:`Shorter Review Cycles, reduce your involvement in coordinating with us. 95% of Projects have been delivered on or before scheduled time. Client Involvement has been cut down by over 50%.</br>
            <b>Your Advantage:</b> Money Saved is Money Earned. You will be in a better financial health. Spend the money to improve your bottom line.`,
        },
    ],
};

export const Team=(props)=> {
    const [ setRowsOption] = useState(null);
    

    return (
        <div id='team'>
        
        <div>
            <h2 className="section-titlee">Frequently Asked Questions(FAQ)</h2>
            
              
            <div className="faq-style-wrapper">
                <Faq data={data} getRowOptions={setRowsOption} />
            </div>

            
        </div>
        </div>
    )
}
