export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
          We are a one-stop-shop for MEP, architecture and engineering firms. From 3D BIM to point cloud to BIM services, CAD services, Scan to BIM, Revit BIM services, Revit family creation services and final shop drawings, we deliver intelligent solutions that capture every aspect of your design and build through enhanced construction documentation.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
