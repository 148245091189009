import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';


const config ={
  width: "400px",
  height: "400px", 
  floating: "true",
  paddingBottom :"30%",
  position:"absolute"
};

class SimpleForm extends Component {
    render() {
      return (
        <ChatBot
          steps={[
            {
              id:'0',
              message:'Got any questions? I am happy to help.',
              trigger:'1',

            },
            {
              id: '1',
              message: ' Before we get started, What is your name?',
              trigger: 'name',
            },
            {
              id: 'name',
              user: true,
              trigger: '3',
            },
            {
              id: '3',
              message: 'Hi {previousValue}! What services are you looking for?',
              trigger: 'services',
            },
            {
              id: 'services',
              options: [
                { value:'BIM', label: 'BIM', trigger: 'end-message' },
                { value: 'Scan To BIM', label: 'Scan to BIM', trigger:'end-message' },
                { value: 'Drafting', label: 'Drafting', trigger: 'end-message' },
                { value: 'Built & As-built', label: 'Built & As-built', trigger: 'end-message' },
                { value: 'All our', label: 'All of the above', trigger: 'end-message' },
              ],
            },
            
            {
              id: 'end-message',
              message: 'Great! We would love to serve you with {previousValue} services!!',
              trigger:'end-messagee',
            },
            {
              id: 'end-messagee',
              message: 'Kindly mail us at projects_bim@bimseas.com',
              trigger:'end-messageee',
            },
            {
              id: 'end-messageee',
              message: 'We will get back to you shortly!',
              end: true,
            },
            ]}
          {...config}
        />
        
      );
    }
  }
  
  export default SimpleForm;
