
import SimpleForm from './SimpleForm'
import './bot.css'

export const Contact = (props) => {
  
  
  
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>READY TO DIGITIZE YOUR SPACE ? </h2>
                <p>
                  Please send us an email  below and we will
                  get back to you as soon as possible.
                </p>
              </div>
             
                <div className='img'>
                  <img src="img/contactus.jpg" className="img-responsive" alt=""></img>
                </div>
                <div id='success'></div>
                
                <a href="mailto:bim_projects@bimseas.com" target='_blank' rel="noopener noreferrer" className="btn btn-custom btn-lg">Mail Us</a>
             
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Studio
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Studio
                </span>{' '}
                {props.data ? props.data.address2 : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i>Office
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
            
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
            
                  <li>
                    <a href={props.data ? props.data.instagram : '/'} target='_blank' rel="noopener noreferrer">
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.linkedin : '/'} target='_blank' rel="noopener noreferrer">
                      <i className='fa fa-linkedin'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'} target='_blank' rel="noopener noreferrer">
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.nft : '/'} target='_blank' rel="noopener noreferrer">
                      <i className='fa fa-rocket'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
        <div className = "footerr"></div>
           <div className = "bot">
       <SimpleForm/>
      </div>      
          <p>
            &copy;2021 BIMSEAS . All rights reserved .
          </p>
          
        </div>
      </div>
    </div>
  )
}
